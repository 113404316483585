import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { FINALIZED_ORDER_STATUS } from 'views/Orders/consts'
import {
  sendComplaintFormActions,
  sendFeedbackFormActions,
} from 'containers/Orders/Details/actions'
import { useClaimForm } from 'components/ContactForm/utils'
import ContactFormModal from 'components/ContactForm/ContactFormModal'
import { BasicButtonV2Grey } from 'components/Button'
import {
  EVENT_ORIGIN,
  PROFILE_EVENTS,
  trackSimpleEvent,
} from 'services/analytics'

import messages from '../../messages'

const CONTACT_FORM_VARIANTS = {
  COMPLAINT: 'complaint',
  FEEDBACK: 'feedback',
}

const ContactForm = ({ orderId, orderStatusId }) => {
  const { formatMessage } = useIntl()
  const [isContactFormOpen, setContactFormOpen] = useState(false)

  const isFinalized = FINALIZED_ORDER_STATUS.includes(orderStatusId)

  const [isSubmitting, onSubmit, submitError] = useClaimForm({
    isContactFormOpen,
    onSubmitSuccess: () => setContactFormOpen(false),
    actionsGroup: isFinalized
      ? sendComplaintFormActions
      : sendFeedbackFormActions,
    additionalData: { id: orderId },
  })

  const contactFormConfig = {
    [CONTACT_FORM_VARIANTS.COMPLAINT]: {
      contactFormTitle: formatMessage(messages.reportComplaint),
      contactFormDescription: formatMessage(messages.contactFormDescription),
    },
    [CONTACT_FORM_VARIANTS.FEEDBACK]: {
      contactFormTitle: formatMessage(messages.reportFeedback),
      contactFormDescription: formatMessage(messages.feedbackFormDescription),
    },
  }

  const { contactFormTitle, contactFormDescription } = isFinalized
    ? contactFormConfig[CONTACT_FORM_VARIANTS.COMPLAINT]
    : contactFormConfig[CONTACT_FORM_VARIANTS.FEEDBACK]

  const handleOpenContactForm = () => {
    setContactFormOpen(true)
    trackSimpleEvent(PROFILE_EVENTS.CONTACT_FORM_CLICKED, {
      origin: EVENT_ORIGIN.PAST_ORDER,
      button: contactFormTitle,
    })
  }

  return (
    <>
      <ContactFormModal
        isOpen={isContactFormOpen}
        onClose={() => setContactFormOpen(false)}
        headingText={contactFormTitle}
        descriptionText={contactFormDescription}
        onSubmit={onSubmit}
        submitDisabled={isSubmitting}
        submitError={submitError}
        hasFileSelector={isFinalized}
      />
      <BasicButtonV2Grey onClick={handleOpenContactForm}>
        {contactFormTitle}
      </BasicButtonV2Grey>
    </>
  )
}

export default ContactForm
