import { makeMessages, makeTranslation } from 'utils'

const messages = {
  title: 'title',
  lastNotifications: 'lastNotifications',
  emptyStateMessage: 'emptyStateMessage',
  unmetLogisticLimitTitle: 'unmetLogisticLimitTitle',
  productBackForStoreTitle: 'productBackForStoreTitle',
  markAsRead: 'markAsRead',
  currentWeek: 'currentWeek',
  older: 'older',
}

export default makeMessages(messages, makeTranslation('notificationCenter'))
